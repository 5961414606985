import type { Dashboard } from '~/models/Dashboard';

export type DashboardState = {
    dashboardStatistics: Dashboard[] | null;
}

export const defaultStateData = (): DashboardState => ({
    dashboardStatistics: null,
});

export const DefaultState = (): DashboardState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
