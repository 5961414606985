import { GET_DASHBOARD_STATISTICS } from './-getter-types';
import { useDashboardStore } from '~/stores/dashboard';
import type { Dashboard } from '~/models/Dashboard';

const Getters = {
    [GET_DASHBOARD_STATISTICS]: (): Dashboard[] | null => {
        const dashboardStore = useDashboardStore();

        return dashboardStore.dashboardStatistics;
    },
};

export default Getters;
