
import { Dashboard } from '../Dashboard';
import { BaseFactory } from './BaseFactory';
import { DashboardCountFactory } from './DashboardCountFactory';
import type { ReadDashboardDto } from '~/types/Dashboard';

export class DashboardFactory extends BaseFactory<ReadDashboardDto, Dashboard> {
    public toModel(dto: ReadDashboardDto): Dashboard {
        const model = new Dashboard();

        if (dto.counts) {
            model.counts = (new DashboardCountFactory()).toModels(dto.counts);
        }

        model.text = dto.text;

        return model;
    }
}
