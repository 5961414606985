import { SET_DASHBOARD_STATISTICS } from './-mutation-types';
import { useDashboardStore } from '~/stores/dashboard';
import DashboardService from '~/services/api/DashboardService';

const Actions = {
    async fetchDashboardStatistics() {
        const dashboardStore = useDashboardStore();
        const dashboardService = new DashboardService();
        const response = await dashboardService.fetchDashboardStatistics();

        dashboardStore[SET_DASHBOARD_STATISTICS](response.data);
    },
};

export default Actions;
