
import { type FetchError } from 'ofetch';
import { DashboardFactory } from '~/models/factories/DashboardFactory';
import BaseApiService from '~/services/api/BaseApiService';
import { apiErrorHandler } from '~/utils/forms/ErrorHandling';
import type { DashboardsOverview, DashboardsOverviewResponse } from '~/types/Dashboard';

export default class DashboardService extends BaseApiService {
    public static basePath = 'dashboard';

    /**
     * @description Fetch dashboard statistics.
     * @returns {Promise<DashboardsOverview>} Promise with the DashboardsOverview as payload
     */
    async fetchDashboardStatistics(): Promise<DashboardsOverview> {
        try {
            const response = await this.baseGet<DashboardsOverviewResponse>(
                this.createPath(DashboardService.basePath),
            );

            const data = (new DashboardFactory()).toModels(response.data);

            // Return it with the data key because we might need to add meta data later (for filtering)
            return {
                data,
            };
        } catch (error) {
            const fetchError = <FetchError>error;
            throw apiErrorHandler(fetchError);
        }
    }
}
