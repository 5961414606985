import { DashboardCount } from '../DashboardCount';
import { BaseFactory } from './BaseFactory';
import type { ReadDashboardCountDto } from '~/types/DashboardCount';

export class DashboardCountFactory extends BaseFactory<ReadDashboardCountDto, DashboardCount> {
    public toModel(dto: ReadDashboardCountDto): DashboardCount {
        const model = new DashboardCount();

        model.count = Number(dto.count);
        model.text = dto.text;

        return model;
    }
}
