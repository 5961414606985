import { SET_DASHBOARD_STATISTICS } from './-mutation-types';
import { useDashboardStore } from '~/stores/dashboard';
import type { Dashboard } from '~/models/Dashboard';

const Mutations = {
    [SET_DASHBOARD_STATISTICS](payload: Dashboard[] | null) {
        const dashboardStore = useDashboardStore();
        dashboardStore.dashboardStatistics = payload;
    },
};

export default Mutations;
